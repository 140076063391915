import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Form as BYForm,
  Modal,
  Badge,
  Button,
  Container,
  Row,
  Col,
  DatePicker
} from 'brickyard-ui';
import 'styles/scenes/observations.scss';
import 'styles/scenes/search.scss';
import { GET_VEHICLE_TYPES } from '@/components/queries/SharedQueries';
import formatDate from '@/utils/formatDate';
import { replaceVehicleTypes } from '../../scenes/TicketsTable/utils';
import { Query } from '@apollo/client/react/components';

const RecentFilters = 'ObservationsRecentFilters';

const defaultFilters = {
  vehicleLicensePlateNumberCont: '',
  countryCodeEq: '',
  vehicleTypeMatches: '',
  vehicleHeadingEq: '',
  observedAtGteq: '',
  observedAtLteq: '',
  delayedFilter: false,
  exemptFilter: false
};

const vehicleHeadingEqTransformer = value => {
  switch (value) {
    case 'plus':
      return I18n.t('observations.observations.details.vehicle_heading_away').toLowerCase();
    case 'minus':
      return I18n.t('observations.observations.details.vehicle_heading_toward').toLowerCase();
    default:
      return '';
  }
};

const getFilterTransformer = key => {
  switch (key) {
    case 'vehicleHeadingEq':
      return vehicleHeadingEqTransformer;
    case 'observedAtGteq':
    case 'observedAtLteq':
      return formatDate;
    default:
      return value => `${value}`;
  }
};

const I18N_FIELD_MAPPING = {
  vehicleLicensePlateNumberCont: I18n.t(
    'observations.observations.search.license_plate_placeholder'
  ),
  countryCodeEq: I18n.t('observations.observations.search.country_code_placeholder'),
  vehicleTypeMatches: I18n.t('observations.observations.search.vehicle_type_select_placeholder'),
  vehicleHeadingEq: I18n.t('observations.observations.search.vehicle_heading'),
  delayedFilter: I18n.t('observations.observations.search.delayed'),
  exemptFilter: I18n.t('observations.observations.search.exempted'),
  observedAtGteq: I18n.t(`observations.observations.search.observed_from`),
  observedAtLteq: I18n.t(`observations.observations.search.observed_until`),
  soortCodeEq: I18n.t('observations.observations.search.offense_type')
};

const handleLicensePlateChange = (event, field) => {
  field.onChange({
    target: { value: event.target.value.toUpperCase(), name: field.name }
  });
};

const handleCheckboxChange = (event, field) => {
  field.onChange({ target: { value: event.target.checked } });
};

const handleDropdownChange = (event, field) => {
  field.onChange({ target: { value: event.target.value } });
};

const handleRecentlyUsedTagsClick = (setFieldValue, key, fieldValue) => {
  setFieldValue(key, fieldValue, false);
};

const SearchObservationDialog = ({ show, onHide, filters, countries, type, setCurrentSearch }) => {
  const [recentTags, setRecentTags] = useState({
    vehicleLicensePlateNumberCont: {
      value: ''
    },
    countryCodeEq: {
      value: ''
    },
    vehicleTypeMatches: {
      value: ''
    },
    vehicleHeadingEq: {
      value: ''
    }
  });

  useEffect(() => {
    try {
      const recentTags = JSON.parse(localStorage.getItem(RecentFilters));
      if (recentTags) {
        setRecentTags(recentTags);
      }
    } catch {
      localStorage.removeItem(RecentFilters);
    }
  }, []);

  const handleSubmit = values => {
    setCurrentSearch(values);
    let mostRecentTags = { ...recentTags };
    Object.keys(values)
      .filter(key => key !== 'exemptFilter' && key !== 'delayedFilter')
      .filter(key => values[key])
      .forEach(key => {
        mostRecentTags[key].value = values[key];
      });
    localStorage.setItem(RecentFilters, JSON.stringify(mostRecentTags));
  };

  return (
    <Modal keyboard="false" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold">
          {I18n.t('observations.observations.search.advanced_filters')}
        </Modal.Title>
      </Modal.Header>
      <Formik enableReinitialize initialValues={filters} onSubmit={handleSubmit}>
        {({ setFieldValue, resetForm }) => (
          <Form>
            <Modal.Body>
              <Container fluid>
                <Row>
                  <Col xs="6">
                    <BYForm.Group>
                      <BYForm.Label>
                        {' '}
                        {I18N_FIELD_MAPPING['vehicleLicensePlateNumberCont']}
                      </BYForm.Label>
                      <Field name="vehicleLicensePlateNumberCont">
                        {({ field }) => (
                          <BYForm.Control
                            as="input"
                            name={field.name}
                            onChange={e => handleLicensePlateChange(e, field)}
                            value={field.value}
                          ></BYForm.Control>
                        )}
                      </Field>
                    </BYForm.Group>
                  </Col>
                  <Col xs="6">
                    <BYForm.Group>
                      <BYForm.Label>{I18N_FIELD_MAPPING['countryCodeEq']}</BYForm.Label>
                      <Field name="countryCodeEq">
                        {({ field }) => (
                          <BYForm.Control
                            as="select"
                            id="countryCodeEq"
                            name={field.name}
                            onChange={evt => handleDropdownChange(evt, field)}
                            {...field}
                          >
                            <option value="" />
                            {(countries || []).sort().map(c => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </BYForm.Control>
                        )}
                      </Field>
                    </BYForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <BYForm.Group>
                      <BYForm.Label>{I18N_FIELD_MAPPING['vehicleTypeMatches']}</BYForm.Label>
                      <Query
                        query={GET_VEHICLE_TYPES}
                        variables={{
                          needRdwConst: true
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading || error) return null;
                          return (
                            <Field name="vehicleTypeMatches">
                              {({ field }) => (
                                <BYForm.Control
                                  as="select"
                                  id="vehicleTypeMatches"
                                  name={field.name}
                                  onChange={evt => handleDropdownChange(evt, field)}
                                  {...field}
                                >
                                  <option value="" />
                                  {data.vehicleTypes.map(vt => (
                                    <option key={vt.name} value={vt.rawName}>
                                      {vt.name}
                                    </option>
                                  ))}
                                </BYForm.Control>
                              )}
                            </Field>
                          );
                        }}
                      </Query>
                    </BYForm.Group>
                  </Col>
                  {type === 'scancar' ? (
                    <Col xs="6">
                      <BYForm.Group>
                        <BYForm.Label>{I18N_FIELD_MAPPING['soortCodeEq']}</BYForm.Label>
                        <Field name="soortCodeEq">
                          {({ field }) => (
                            <BYForm.Control
                              as="select"
                              id="soortCodeEq"
                              name={field.name}
                              label="soortCodeEq"
                              onChange={evt => handleDropdownChange(evt, field)}
                              {...field}
                            >
                              <option value="" />
                              {['bluezone', 'fiscaal', 'niet fiscaal'].map(option => (
                                <option key={option} value={option.replace(' ', '_').toUpperCase()}>
                                  {option}
                                </option>
                              ))}
                            </BYForm.Control>
                          )}
                        </Field>
                      </BYForm.Group>
                    </Col>
                  ) : (
                    <Col xs="6">
                      <BYForm.Group>
                        <BYForm.Label>{I18N_FIELD_MAPPING['vehicleHeadingEq']}</BYForm.Label>
                        <Field name="vehicleHeadingEq">
                          {({ field }) => (
                            <BYForm.Control
                              as="select"
                              id="vehicleHeading"
                              name={field.name}
                              label="vehicleHeading"
                              onChange={evt => handleDropdownChange(evt, field)}
                              {...field}
                            >
                              <option value=""></option>
                              <option value="plus">
                                {I18n.t('observations.observations.details.vehicle_heading_away')}
                              </option>
                              <option value="minus">
                                {I18n.t('observations.observations.details.vehicle_heading_toward')}
                              </option>
                            </BYForm.Control>
                          )}
                        </Field>
                      </BYForm.Group>
                    </Col>
                  )}
                  <Col xs="6">
                    <BYForm.Group>
                      <BYForm.Label>{I18N_FIELD_MAPPING['observedAtGteq']}</BYForm.Label>
                      <Field name="observedAtGteq">
                        {({ field }) => (
                          <DatePicker
                            showTimePicker
                            {...field}
                            onChange={date =>
                              setFieldValue(field.name, new Date(date).toISOString())
                            }
                          />
                        )}
                      </Field>
                    </BYForm.Group>
                  </Col>
                  <Col xs="6">
                    <BYForm.Group>
                      <BYForm.Label>{I18N_FIELD_MAPPING['observedAtLteq']}</BYForm.Label>
                      <Field name="observedAtLteq">
                        {({ field }) => (
                          <DatePicker
                            showTimePicker
                            {...field}
                            onChange={date =>
                              setFieldValue(field.name, new Date(date).toISOString())
                            }
                          />
                        )}
                      </Field>
                    </BYForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <Field name="delayedFilter" label={I18N_FIELD_MAPPING['delayedFilter']}>
                      {({ field }) => (
                        <BYForm.Check
                          type="switch"
                          id="delayedFilter"
                          name={field.name}
                          label={I18N_FIELD_MAPPING['delayedFilter']}
                          onChange={evt => handleCheckboxChange(evt, field)}
                          checked={field.value}
                          {...field}
                        ></BYForm.Check>
                      )}
                    </Field>
                  </Col>
                  <Col xs="6">
                    <Field name="exemptFilter" label={I18N_FIELD_MAPPING['exemptFilter']}>
                      {({ field }) => (
                        <BYForm.Check
                          type="switch"
                          id="exemptFilter"
                          name={field.name}
                          label={I18N_FIELD_MAPPING['exemptFilter']}
                          onChange={evt => handleCheckboxChange(evt, field)}
                          checked={field.value}
                          {...field}
                        ></BYForm.Check>
                      )}
                    </Field>
                  </Col>
                </Row>
                <br />
                {Object.values(recentTags).some(tag => !!tag.value) && (
                  <div className="recent-tags-container">
                    <p>{I18n.t('observations.observations.search.recent_filters')}: </p>
                    {Object.keys(recentTags).map(
                      key =>
                        recentTags[key].value && (
                          <span key={key} className="class-with-margin">
                            <Badge
                              variant="outline-by-secondary"
                              key={key}
                              onClick={() => {
                                handleRecentlyUsedTagsClick(
                                  setFieldValue,
                                  key,
                                  recentTags[key].value
                                );
                              }}
                            >
                              {I18N_FIELD_MAPPING[key]}: {recentTags[key].value}
                            </Badge>
                          </span>
                        )
                    )}
                  </div>
                )}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Container fluid>
                <Row>
                  <Col xs="6">
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => {
                        resetForm({ values: defaultFilters });
                      }}
                    >
                      {I18n.t(`observations.observations.search.reset`)}
                    </Button>
                  </Col>
                  <Col xs="6">
                    <span className="right-buttons">
                      <span className="class-with-margin">
                        <Button variant="by-dark" onClick={onHide}>
                          {I18n.t(`actions.cancel`)}
                        </Button>
                      </span>
                      <Button variant="by-primary" type="submit" onClick={onHide}>
                        {I18n.t(`observations.observations.search.search_action`)}
                      </Button>
                    </span>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export { defaultFilters, getFilterTransformer, I18N_FIELD_MAPPING };
export default SearchObservationDialog;
