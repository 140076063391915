import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MessagePopup, Modal } from 'brickyard-ui';
import { ShortDateFormat } from '@/utils/DateFormats';
import { FaRegImages } from 'react-icons/fa';
import { ALLOWED_IMAGE_EXTENSIONS, ALLOWED_VIDEO_EXTENSIONS } from '../../../../../utils/consts';

import styled from 'styled-components';
import usePermission from '@/hooks/usePermission';
import formatDate from '@/utils/formatDate';
import Button from '../../components/Button';
import MiscListItem from './MiscListItem';
import ErrorWrapper from '../components/ErrorWrapper';
import ImageCarousel from '../../../../Cop/UseCaseResultImages/components/ImageCarousel';

const StyledButton = styled(Button)`
  font-size: 13px;
  margin: 8px 0;
`;

const StyledDeleteButton = styled.button`
  line-height: 19px;
  font-size: 10px;
  color: #f36c21;
  border: none;
  background: none;
`;

const Wrapper = styled.div`
  input {
    display: none;
  }

  .tickets-slider:hover {
    cursor: pointer;
  }
`;

const MAX_ATTACHMENT_SIZE = 8; // MB

const Attachments = ({ attachments, formik, disabled }) => {
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [deletingAtt, setDeletingAtt] = useState(null);
  const [confirmationVisible, setConfirmationVisibility] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(null);
  const fileInputRef = useRef(null);
  const canCreateAttachment = usePermission('attachmentsCreate');
  const IMAGE_SHORT_TYPE_ORDER = ['ov', 'ir', 'pd', 'undefined'];
  const canEdit = usePermission('edit');

  const imageSortByName = (a, b) => {
    const aIndex = IMAGE_SHORT_TYPE_ORDER.indexOf(getTypeFromName(a.link.name));
    const bIndex = IMAGE_SHORT_TYPE_ORDER.indexOf(getTypeFromName(b.link.name));

    return aIndex - bIndex || a.link.name.localeCompare(b.link.name);
  };

  const getTypeFromName = name => {
    let name_parts = name.split('_');
    return name_parts[name_parts.length - 2] || 'undefined';
  };

  const files = attachments
    .map(({ id, created_at, user_name, _url, file_name }) => ({
      id,
      date: formatDate(created_at, ShortDateFormat),
      author: user_name,
      link: { url: `/tickets/attachments/${id}/${file_name}`, name: file_name }
    }))
    .sort(imageSortByName);

  const is_image = file_name => {
    // eslint-disable-next-line no-undef
    return _.includes(ALLOWED_IMAGE_EXTENSIONS, _.last(_.split(file_name, '.')));
  };

  const is_video = file_name => {
    // eslint-disable-next-line no-undef
    return _.includes(ALLOWED_VIDEO_EXTENSIONS, _.last(_.split(file_name, '.')));
  };

  const image_files = attachments
    .filter(attachment => is_image(attachment.file_name))
    .map(({ id, file_name, virtual_lines }) => ({
      url: `/tickets/attachments/${id}/${file_name}`,
      type: 'general',
      lines: virtual_lines,
      id: id
    }));

  const video_files = attachments
    .filter(attachment => is_video(attachment.file_name))
    .map(({ id, file_name }) => ({
      url: `/tickets/attachments/${id}/${file_name}`,
      type: 'video'
    }));

  const carouselFiles = [...image_files, ...video_files];

  const [attachmentError, setAttachmentError] = useState(null);

  const okBtn = {
    label: I18n.t('actions.confirm'),
    onClick: () => {
      formik.deleteAttachment(deletingAtt);
      setConfirmationVisibility(false);
    }
  };
  const cancelBtn = {
    label: I18n.t('actions.cancel'),
    onClick: () => {
      setDeletingAtt(null);
      setConfirmationVisibility(false);
    }
  };

  const fileInputHandler = fileInputEvent => {
    setLoading(true);
    const newFiles = [...fileInputEvent.target.files];
    const preview = [];

    newFiles.forEach(f => {
      const fileSizeInMB = f.size / Math.pow(2, 20);

      if (fileSizeInMB > MAX_ATTACHMENT_SIZE) {
        setAttachmentError(I18n.t('tickets.form.sections.miscellaneous.errors.file_size_limit'));
        setLoading(false);
        return;
      }
      setAttachmentError(null);

      const fileReader = new FileReader();
      fileReader.onload = evt => {
        preview.push({
          id: Date.now(),
          url: evt.target.result,
          file_name: f.name,
          user_name: user.name,
          created_at: new Date()
        });
        if (preview.length === newFiles.length) {
          const newAttachments = [...attachments, ...preview];
          formik.setFieldValue('attachments', newAttachments);
          setLoading(false);
        }
      };
      fileReader.readAsDataURL(f);
    });
    formik.setFieldValue('attachmentsUpload', newFiles);
  };

  const prepareDeletion = id => {
    setDeletingAtt(id);
    setConfirmationVisibility(true);
  };

  return (
    <Wrapper>
      <i className="tickets-slider float-right">
        <FaRegImages size={20} onClick={() => setDefaultOpen(0)} />
      </i>

      {defaultOpen != null && (
        <Modal
          size="xl"
          centered
          show={defaultOpen !== null}
          onHide={() => setDefaultOpen(null)}
          style={{ display: 'flex' }}
        >
          <Modal.Body style={{ backgroundColor: '#0e0e0e', borderRadius: '3px' }}>
            <div style={{ width: '1106px', height: '700px' }}>
              <ImageCarousel
                defaultOpen={defaultOpen}
                onCloseBtnClick={() => setDefaultOpen(null)}
                imageList={carouselFiles}
                showHeader={false}
                showHeaderSliders={true}
                showFilterOptions={false}
                showBlur={canEdit && user.blurring_enabled_for_cop}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      <input type="file" multiple onChange={fileInputHandler} ref={fileInputRef} />
      {!disabled && !formik.status.readOnly && canCreateAttachment && (
        <StyledButton disabled={loading} onClick={() => fileInputRef.current.click()}>
          {I18n.t('tickets.full_ticket.actions.upload_file')}
        </StyledButton>
      )}
      {attachmentError && (
        <ErrorWrapper style={{ position: 'relative', marginLeft: '10px' }}>
          {attachmentError}
        </ErrorWrapper>
      )}
      <div>
        {files.map((file, index) => (
          <MiscListItem
            key={Math.random() + file.id + index}
            date={file.date}
            author={file.author}
            text={file.link.name}
            url={file.link.url}
            helper={
              !disabled &&
              !formik.status.readOnly && (
                <StyledDeleteButton onClick={() => prepareDeletion(file.id)}>
                  {I18n.t('actions.delete')}
                </StyledDeleteButton>
              )
            }
          />
        ))}
      </div>

      <MessagePopup
        type="confirmation"
        size="md"
        title={I18n.t('tickets.form.sections.attachments')}
        message={I18n.t('tickets.dialogs.attachment.confirm_delete')}
        show={confirmationVisible}
        onHide={() => setConfirmationVisibility(false)}
        okBtn={okBtn}
        cancelBtn={cancelBtn}
      />
    </Wrapper>
  );
};

export default Attachments;
