import { useEffect } from 'react';
import gql from 'graphql-tag';
import { useFormik } from 'formik';

import { MINIMAL_TICKET_FRAG, DETAILED_TICKET_FRAG } from '@/components/queries/tickets';

import TicketSchema from '@/utils/tickets/TicketSchema';
import Api from '@/utils/Api';
import { emptyToNull, nullToUndefined } from '@/utils/CastFieldTypes';
import axios from 'axios';
import { useMutation } from '@apollo/client';

export const SAVE_TICKET = gql`
  mutation SaveTicket(
    $id: ID
    $saveIt: Boolean
    $completeIt: Boolean
    $type: String
    $writtenAt: ISO8601DateTime
    $partnerId: ID
    $creatorId: ID
    $project: String
    $offenseCode: String
    $offenseFineAmountInCents: Int
    $offenseFineStateAttorneyName: String
    $offenseCategory: String
    $offenseClarification: String
    $offenseIsTowable: Boolean
    $vehicleLicensePlateNumber: String
    $vehicleLicensePlateDuplicateCode: Int
    $vehicleCountry: String
    $vehicleType: String
    $vehicleMake: String
    $vehicleColor: String
    $offenseLocationCity: String
    $offenseLocationStreet: String
    $offenseLocationHousenumber: String
    $offenseLocationHousenumberAddition: String
    $offenseParkingArea: String
    $offenseLocationRoadType: Int
    $offenseLocationWithinCityLimits: Boolean
    $offenseLocationRemark: String
    $suspectIdentificationType: SuspectIdentificationTypes
    $suspectIdentificationCode: String
    $suspectSsn: String
    $suspectFirstnames: String
    $suspectMiddlename: String
    $suspectLastname: String
    $suspectGender: SuspectGenderTypes
    $suspectZvwovp: Boolean
    $suspectResidenceCountry: String
    $suspectResidenceZipcode: String
    $suspectResidenceCity: String
    $suspectResidenceStreet: String
    $suspectResidenceHousenumber: String
    $suspectResidenceHousenumberAddition: String
    $suspectBirthDate: ISO8601Date
    $suspectBirthCountry: String
    $suspectBirthCity: String
    $suspectIsResident: Boolean
    $statementSuspect: String
    $explainSuspectOffence: Boolean
    $explainLegalAid: Boolean
    $explainRightTranslator: Boolean
    $suspectWaivedInterrogation: Boolean
    $languageOfInterrogation: String
    $interpreterNumber: String
    $cautie: Int
    $handcuffsUsed: Boolean
    $statementOfficer: String
    $remarkOfficer: String
    $isWarning: Boolean
  ) {
    createTicket(
      id: $id
      saveIt: $saveIt
      completeIt: $completeIt
      type: $type
      writtenAt: $writtenAt
      partnerId: $partnerId
      creatorId: $creatorId
      project: $project
      offenseCode: $offenseCode
      offenseFineAmountInCents: $offenseFineAmountInCents
      offenseFineStateAttorneyName: $offenseFineStateAttorneyName
      offenseCategory: $offenseCategory
      offenseClarification: $offenseClarification
      offenseIsTowable: $offenseIsTowable
      vehicleLicensePlateNumber: $vehicleLicensePlateNumber
      vehicleLicensePlateDuplicateCode: $vehicleLicensePlateDuplicateCode
      vehicleCountry: $vehicleCountry
      vehicleType: $vehicleType
      vehicleMake: $vehicleMake
      vehicleColor: $vehicleColor
      offenseLocationCity: $offenseLocationCity
      offenseLocationStreet: $offenseLocationStreet
      offenseLocationHousenumber: $offenseLocationHousenumber
      offenseLocationHousenumberAddition: $offenseLocationHousenumberAddition
      offenseParkingArea: $offenseParkingArea
      offenseLocationRoadType: $offenseLocationRoadType
      offenseLocationWithinCityLimits: $offenseLocationWithinCityLimits
      offenseLocationRemark: $offenseLocationRemark
      suspectIdentificationType: $suspectIdentificationType
      suspectIdentificationCode: $suspectIdentificationCode
      suspectSsn: $suspectSsn
      suspectFirstnames: $suspectFirstnames
      suspectMiddlename: $suspectMiddlename
      suspectLastname: $suspectLastname
      suspectGender: $suspectGender
      suspectZvwovp: $suspectZvwovp
      suspectResidenceCountry: $suspectResidenceCountry
      suspectResidenceZipcode: $suspectResidenceZipcode
      suspectResidenceCity: $suspectResidenceCity
      suspectResidenceStreet: $suspectResidenceStreet
      suspectResidenceHousenumber: $suspectResidenceHousenumber
      suspectResidenceHousenumberAddition: $suspectResidenceHousenumberAddition
      suspectBirthDate: $suspectBirthDate
      suspectBirthCountry: $suspectBirthCountry
      suspectBirthCity: $suspectBirthCity
      suspectIsResident: $suspectIsResident
      statementSuspect: $statementSuspect
      explainSuspectOffence: $explainSuspectOffence
      explainLegalAid: $explainLegalAid
      explainRightTranslator: $explainRightTranslator
      suspectWaivedInterrogation: $suspectWaivedInterrogation
      languageOfInterrogation: $languageOfInterrogation
      interpreterNumber: $interpreterNumber
      cautie: $cautie
      handcuffsUsed: $handcuffsUsed
      statementOfficer: $statementOfficer
      remarkOfficer: $remarkOfficer
      isWarning: $isWarning
    ) {
      ...MinimalTicketFrag
      ...DetailedTicketFrag
    }
  }
  ${MINIMAL_TICKET_FRAG}
  ${DETAILED_TICKET_FRAG}
`;

const useTicketsForm = (initialType = 'fiscal', initialValues = {}, afterSave = () => {}) => {
  const type = initialValues.type || initialType;
  const error = '';
  const [saveTicket] = useMutation(SAVE_TICKET);

  const juridicalValues = {
    // Offense information - Offense
    handcuffsUsed: false,
    offenseLocationWithinCityLimits: false,
    offenseLocationRoadType: null,
    offenseLatitude: null,
    offenseLongitude: null,
    // Suspect information - Identity
    suspectIdentificationType: '',
    suspectIdentificationCode: '',
    suspectSsn: '',
    suspectFirstnames: '',
    suspectMiddlename: '',
    suspectLastname: '',
    suspectGender: '',
    suspectBirthDate: null,
    suspectBirthCountry: '',
    suspectBirthCity: '',
    suspectIsResident: true,
    // Suspect information - residence
    suspectZvwovp: false,
    suspectResidenceCity: '',
    suspectResidenceCountry: '',
    suspectResidenceHousenumber: '',
    suspectResidenceHousenumberAddition: '',
    suspectResidenceStreet: '',
    suspectResidenceZipcode: '',
    // Statement fields
    explainLegalAid: false,
    explainRightTranslator: false,
    explainSuspectOffence: false,
    suspectWaivedInterrogation: false,
    languageOfInterrogation: '',
    interpreterNumber: '',
    statementSuspect: '',
    cautie: null
  };

  const sendMutation = async (values, isDraft) => {
    formik.setStatus('sending');
    try {
      const ticket = emptyToNull({ ...values });
      ticket.cautie = parseInt(ticket.cautie);
      ticket.vehicleLicensePlateDuplicateCode = parseInt(ticket.vehicleLicensePlateDuplicateCode);
      let attachmentsUpload = [];

      if (values.attachmentsUpload) {
        attachmentsUpload = [...values.attachmentsUpload];
        delete ticket.attachmentsUpload;
      }

      ticket.saveIt = true;
      ticket.completeIt = !isDraft;

      delete ticket.attachmentsUpload;
      delete ticket.attachments;

      const res = await saveTicket({ variables: ticket });

      if (attachmentsUpload?.length) {
        const formData = new FormData();

        formData.set('record_id', res.data.createTicket.id);
        formData.set('record_type', 'TICKET');

        let uploads = attachmentsUpload.map(file => {
          formData.set('attachment', file);

          return Api.post('/api/v2/graphql_uploads', formData, {
            headers: { ...Api.headers, 'Content-Type': 'multipart/form-data' }
          });
        });

        await axios.all(uploads);
      }

      formik.setStatus('updated');
    } catch (err) {
      formik.setStatus('error');
      console.log(err);
    }
  };

  const onSubmit = async values => {
    values.vehicleLicensePlateDuplicateCode =
      parseInt(values.vehicleLicensePlateDuplicateCode) || '';
    sendMutation(values, false);
  };

  const bypassValidation = async values => {
    sendMutation(values, true);
  };

  const deleteAttachment = async id => {
    try {
      if (id && formik.values.id) {
        await Api.delete(`/api/v2/graphql_uploads/${id}`, {
          data: {
            record_type: 'TICKET',
            record_id: formik.values.id,
            id
          }
        });
        const removedEl = formik.values.attachments.find(a => a.id === id);
        formik.setFieldValue(
          'attachments',
          formik.values.attachments.filter(a => a.id !== id)
        );
        formik.setFieldValue(
          'attachmentsUpload',
          formik.values.attachmentsUpload.filter(a => a.name !== removedEl.file_name)
        );
      }
    } catch (err) {
      formik.setStatus('error');
      console.log(err);
    }
  };

  const getTicketInitialValues = () => {
    if (initialValues?.type === 'juridical') return initialValues;

    let defaultValues = {
      id: null,
      type,
      saveIt: true,
      completeIt: false,
      // Vehicle information
      vehicleLicensePlateNumber: '',
      vehicleLicensePlateDuplicateCode: null,
      vehicleCountry: '',
      vehicleType: '',
      vehicleColor: '',
      vehicleMake: '',
      // General information
      writtenAt: new Date(),
      partnerId: '',
      partnerUsername: '',
      project: '',
      // Offense information - Offense
      offenseCode: '',
      offenseCategory: '',
      offenseClarification: '',
      // Offense information - Location
      offenseParkingArea: '',
      offenseFineAmountInCents: 0,
      offenseFineStateAttorneyName: '',
      offenseLocationCity: '',
      offenseLocationStreet: '',
      offenseLocationHousenumber: '',
      offenseLocationHousenumberAddition: '',
      offenseLatitude: null,
      offenseLongitude: null,
      offenseIsTowable: false,
      locationRemarks: '',
      offenseLocationRemark: '',
      // Statements
      statementOfficer: '',
      remarkOfficer: '',
      // Attachments
      attachments: [],
      // Attachments upload value, used for new files
      attachmentsUpload: [],
      isWarning: false,
      sourceSystem: ''
    };

    if (type === 'juridical') {
      defaultValues = {
        ...defaultValues,
        ...juridicalValues
      };
    }

    /*
     * This conditional is reponsible to filter fields accordingly to the ticket type.
     *
     * Please check:
     * https://app.zenhub.com/workspaces/workspace-of-development-59d74fd0deeccb7c89a70172/issues/brickparking/gaudi-portal/3554
     *
     */
    if (Object.keys(initialValues).length) {
      defaultValues = Object.keys(defaultValues).reduce((obj, key) => {
        return { ...obj, [key]: initialValues[key] };
      }, {});
    }

    return defaultValues;
  };

  const formik = useFormik({
    initialValues: nullToUndefined({
      type,
      ...getTicketInitialValues()
    }),
    initialStatus: { readOnly: false },
    onSubmit,
    validateOnChange: true,
    validationSchema: () => TicketSchema
  });

  formik.bypassValidation = () => bypassValidation(formik.values, true);
  formik.deleteAttachment = id => deleteAttachment(id);

  useEffect(() => {
    formik && formik.setFieldValue('type', type);
    formik.setFieldValue('offenseCode', '');
    if (type === 'juridical') {
      for (const key in juridicalValues) {
        formik.setFieldValue(key, juridicalValues[key]);
      }
    }
    if (type === 'ticket') {
      for (const key in juridicalValues) {
        formik.setFieldValue(key, undefined);
      }
    }
  }, [type]);

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      formik.setValues(getTicketInitialValues());
    }
  }, [initialValues]);

  useEffect(() => {
    if (formik.status === 'updated') {
      afterSave();
    }
  }, [formik.status]);

  return [formik, error];
};

export default useTicketsForm;
