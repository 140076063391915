import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ImageCarousel from './components/ImageCarousel';
import { useSelector } from 'react-redux';

const UseCaseResultImages = ({
  useCaseResult,
  isEditable,
  setIsEditable,
  canEdit,
  formik,
  loading
}) => {
  const [defaultOpen, setDefaultOpen] = useState(null);
  const user = useSelector(state => state.user);

  const openInNewTab = url => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onSubmitForm = () => {
    setIsEditable(false);
    formik.handleSubmit();
  };

  const onCancelForm = () => {
    formik.handleReset();
    setIsEditable(false);
  };

  return (
    useCaseResult && (
      <>
        <ImageCarousel
          useCaseResult={useCaseResult}
          onImageClick={(_img, index) => setDefaultOpen(index)}
          onSubmitForm={onSubmitForm}
          onCancelForm={onCancelForm}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          canEdit={canEdit}
          loading={loading}
          formik={formik}
        />
        {defaultOpen !== null && (
          <Modal
            size="xl"
            centered
            show={defaultOpen !== null}
            onHide={() => setDefaultOpen(null)}
            style={{ display: 'flex' }}
          >
            <Modal.Body style={{ backgroundColor: '#0e0e0e', borderRadius: '3px' }}>
              <div style={{ width: '1106px', height: '700px' }}>
                <ImageCarousel
                  useCaseResult={useCaseResult}
                  onImageClick={img => openInNewTab(img.url)}
                  onCloseBtnClick={() => setDefaultOpen(null)}
                  defaultOpen={defaultOpen}
                  showHeader={true}
                  showHeaderSliders={true}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  canEdit={canEdit}
                  showBlur={canEdit && user.cop_suspect_blurring}
                  loading={loading}
                  formik={formik}
                  onSubmitForm={onSubmitForm}
                  onCancelForm={onCancelForm}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  );
};

export default UseCaseResultImages;
