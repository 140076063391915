import gql from 'graphql-tag';

export const GET_VALIDATED_USE_CASE_RESULTS_BY_USER = gql`
  query GetValidatedObservationByUser($userId: ID!, $useCaseId: [ID!]!, $validatedFilter: Boolean) {
    useCaseResults(
      search: {
        useCaseId: $useCaseId
        validatedByIdEqOrValidatedForDeletionByIdEq: $userId
        validatedFilter: $validatedFilter
        statusIn: ["validated", "marked_for_deletion"]
      }
      first: 100
    ) {
      nodes {
        id
        attachments {
          id
        }
        validatedType
        validatedForDeletion
        validatedBy {
          id
        }
      }
    }
  }
`;

export const MINIMAL_USE_CASE_RESULT_FRAG = gql`
  fragment MinimalUseCaseResultFrag on UseCaseResult {
    id
    observedAt
    vehicleHeading
    accuracy
    vehicleLicensePlateNumber
    countryCode
    status
    delayed
    exempt
    evidence
    linkedUcrId
    offenseCategory
    offenseCode
    validatedForDeletion
    useCaseResultPermission
    camera {
      name
    }
    observation {
      id
      type
    }
    validatedBy {
      id
      name
    }
    validatedType
    location
    canBeEvaluatedInBackoffice
    needsFollowUp
    manualFollowUp
    assignedEnforcerName
  }
`;

export const GET_DETAILED_USE_CASE_RESULT = gql`
  query GetDetailedUseCaseResult($id: [ID!], $useCaseId: [ID!]!, $validatedFilter: Boolean) {
    useCaseResults(
      search: {
        idIn: $id
        useCaseId: $useCaseId
        delayedFilter: true
        exemptFilter: true
        validatedFilter: $validatedFilter
      }
      first: 1
    ) {
      nodes {
        ...MinimalUseCaseResultFrag
        blueZone
        showSuspect
        pipelineFailed
        cameraOverviewImage
        useCaseId
        vehicleType
        vehicleMake
        vehicleColor
        vehicleLatitude
        vehicleLongitude
        vehicleWidth
        vehicleWeight
        vehicleMaxWeight
        vehicleEmissionCode
        vehicleFuelType
        offenseLocationCity
        offenseLocationStreet
        rdwAdmittance
        speed
        ticketable
        parkingAreaCode
        objectTypeConfidence
        railwayViolationData
        railwaySpeedLimit
        railwayPardonTime
        cameraCity
        cameraStreet
        cameraMunicipality
        duplicateCode
        useCase {
          name
          imageUrl
          dismissalReasonRequired
          exemptionEnabled
          warningEnabled
        }
        exemptionRequest {
          id
        }
        rightResponses {
          id
          querySentAt
          datetimeUsedInQuery
          providerName
          description
          hasRight
        }
        files {
          id
          url
          kind
          virtualLines
          useCaseResultId
          images {
            id
            url
            kind
            useCaseResultId
          }
        }
        panoramaFiles {
          url
          kind
        }
        videoFiles {
          id
          url
          kind
        }
        previousWarnings {
          count
          lastDatetime
          sourceSystem
        }
        previousTickets {
          count
          lastDatetime
          sourceSystem
        }
        relatedUseCaseResults {
          ...MinimalUseCaseResultFrag
          blueZone
          useCaseId
          vehicleType
          vehicleMake
          vehicleColor
          vehicleLatitude
          vehicleLongitude
          vehicleWidth
          vehicleWeight
          vehicleMaxWeight
          vehicleEmissionCode
          vehicleFuelType
          offenseCode
          offenseCategory
          offenseLocationStreet
          rdwAdmittance
          speed
          ticketable
          objectTypeConfidence
          railwayViolationData
          railwaySpeedLimit
          railwayPardonTime
          rightResponses {
            id
            querySentAt
            datetimeUsedInQuery
            providerName
            description
            hasRight
          }
          files {
            id
            url
            kind
            virtualLines
            useCaseResultId
            images {
              id
              url
              kind
              useCaseResultId
            }
          }
          panoramaFiles {
            url
          }
          videoFiles {
            id
            url
            kind
          }
          previousWarnings {
            count
            lastDatetime
            sourceSystem
          }
          previousTickets {
            count
            lastDatetime
            sourceSystem
          }
          useCase {
            id
            imageUrl
            dismissalReasonRequired
            exemptionEnabled
            warningEnabled
          }
        }
      }
    }
  }
  ${MINIMAL_USE_CASE_RESULT_FRAG}
`;

export const GET_MIN_USE_CASE_RESULT = gql`
  query GetMinUseCaseResult($id: [ID!], $useCaseId: [ID!]!, $first: Int = 1) {
    useCaseResults(search: { idIn: $id, useCaseId: $useCaseId }, first: $first) {
      nodes {
        ...MinimalUseCaseResultFrag
      }
    }
  }
  ${MINIMAL_USE_CASE_RESULT_FRAG}
`;

export const GET_USE_CASE_RESULTS = gql`
  query GetUseCaseResults(
    $search: UseCaseResultSearch!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    useCaseResults(search: $search, first: $first, last: $last, after: $after, before: $before) {
      edges {
        cursor
        node {
          ...MinimalUseCaseResultFrag
          useCase {
            id
            imageUrl
            dismissalReasonRequired
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
    }
    copMeta {
      id(search: $search)
      observationsCountries(search: $search)
      observationTypes(search: $search)
    }
  }
  ${MINIMAL_USE_CASE_RESULT_FRAG}
`;

export const GET_OBSERVATIONS_FOR_MAP = gql`
  query GetObservationsForMap($search: UseCaseResultSearch!, $first: Int) {
    useCaseResults(search: $search, first: $first) {
      edges {
        cursor
        node {
          id
          vehicleLatitude
          vehicleLongitude
        }
      }
    }
  }
`;

export const GET_USE_CASE_RESULT_LOGS = gql`
  query GetUseCaseResultLogs($id: [ID!], $useCaseId: [ID!]!, $validatedFilter: Boolean) {
    useCaseResults(
      search: {
        idIn: $id
        useCaseId: $useCaseId
        delayedFilter: true
        exemptFilter: true
        validatedFilter: $validatedFilter
      }
      first: 1
    ) {
      nodes {
        id
        logs
      }
    }
  }
`;

export const GET_USE_CASE_INCIDENTAL_PIPELINES = gql`
  query GetUseCaseIncidentalPipelines($id: ID!) {
    useCaseIncidentalPipelines(id: $id) {
      id
      name
    }
  }
`;

export const RECORD_UNDO = gql`
  mutation RecordUseCaseResultUndo($id: ID!) {
    recordUseCaseResultUndo(id: $id) {
      id
    }
  }
`;

export const GET_USE_CASE_RESULT_COUNT = gql`
  query GetResultCount($search: UseCaseResultSearch!) {
    useCaseResults(search: $search) {
      totalCount
    }
  }
`;
