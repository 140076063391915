import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import OffenseClarificationsSearch from './../../../TicketsTable/search/OffenseClarificationsSearch';
import Button from '../../components/Button';
import Card from '../components/Card';
import Select from '../components/LabelSelect';
import Toggle from '../components/LabelToggle';
import Textarea from '../components/LabelTextarea';
import Amount from '../components/Amount';
import OffenseInput from './OffenseInput';
import { GET_OFFENSES } from '@/components/queries/SharedQueries';
import usePermission from '@/hooks/usePermission';

const TowableToggle = styled(Toggle)`
  width: 83px;
  .toggle-switch-disabled {
    cursor: not-allowed;
    .toggle-switch-inner {
      background-color: #ff6f00;
      &:before {
        background-color: #ff6f00;
      }
    }
  }
  input:checked + .toggle-switch-div {
    border: 0.5px solid #ff6f00;
  }
`;

const WarningToggle = TowableToggle;

const ClarificationTextarea = styled(Textarea)`
  textarea {
    height: 70px;
  }
`;

const ClarificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 10px 0;

  button {
    font-size: 12px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  gap: 18px;
  justify-content: space-between;
`;

const OffenseCard = ({ formik, ticketData = {}, disabled = false }) => {
  const [offenseCategories, setOffenseCategories] = useState([]);
  const [offenseClarificationModalVisible, setOffenseClarificationVisibility] = useState(false);
  const {
    offenseIsTowable,
    type,
    isWarning,
    offenseCategory,
    offenseCode,
    offenseParkingArea,
    offenseFineAmountInCents
  } = formik.values;
  const {
    showFineSurcharge,
    fineSurchargeAmountInCents,
    offenseExtraFineAmountInCents
  } = ticketData;

  const editOffenseCode = usePermission('editOffenseCode');
  const canEditOffenseCode = !disabled && editOffenseCode;

  const [offense, setOffense] = useState(null);
  const [search, setSearch] = useState('');

  const { loading, data } = useQuery(GET_OFFENSES, {
    variables: { code: search, type: type || null },
    onCompleted: response => {
      if (search) {
        setOffenseByCategory(response);
      }
    }
  });

  const getOffenseList = () => {
    if (disabled || formik.status.readOnly || loading || !data) {
      return [];
    }
    return data.offenses.map(offense => ({
      ...offense,
      label: `${offense.offenseCode} - ${offense.offenseCategoryDescription}`
    }));
  };

  const setOffenseByCategory = response => {
    if (!response) {
      return;
    }

    const offenseFound = structuredClone(
      response.offenses.find(
        o =>
          o.offenseCode === formik.values.offenseCode && o.offenseCategoryGroup === offenseCategory
      )
    );

    if (
      offenseFound &&
      formik?.values?.offenseFineAmountInCents != offenseFound?.fineAmountInCents
    ) {
      offenseFound.fineAmountInCents = formik.values.offenseFineAmountInCents;
    }
    setOffense(offenseFound);
  };

  const handleOffenseInfo = (offense, fetchNewValues = false) => {
    if (!offense) {
      return;
    }
    if (formik.values.offenseCode === offense.offenseCode && !fetchNewValues) return;
    let newValues = {
      offenseCode: offense.offenseCode,
      offenseCategory: offense.offenseCategoryGroup
    };
    if (type === 'juridical' || type === 'fiscal') {
      newValues.vehicleInformationValidation = offense.vehicleInformationValidation;
    }
    if (type === 'juridical') {
      newValues.personalInformationValidation = offense.personalInformationValidation;
      if (isWarning) {
        newValues.offenseFineAmountInCents = 0;
      } else {
        newValues.offenseFineAmountInCents = offense.fineAmountInCents || 0;
      }
    }
    formik.setValues({
      ...formik.values,
      ...newValues
    });

    setOffenseCategories(offense.offenseCategories);
  };

  useEffect(() => {
    handleOffenseInfo(offense, true);
  }, [offense, search]);

  const offenseCategoryOptions = offenseCategories.map(({ categoryGroup, description }) => ({
    id: categoryGroup,
    name: description + categoryGroup
  }));

  return (
    <Card
      title={I18n.t('tickets.form.title.offense')}
      colorScheme={{ primaryColor: '#B0AF38', secondaryColor: '#FBF9F3' }}
    >
      <div className="flex-between">
        <OffenseInput
          isInvalid={formik.errors.offenseCode}
          disabled={!canEditOffenseCode}
          readOnly={formik.status.readOnly}
          offense={offense}
          search={search}
          setSearch={setSearch}
          setOffense={setOffense}
          onOffense={handleOffenseInfo}
          offenseList={getOffenseList()}
          showDescription
          error={formik.errors.offenseCode}
          {...formik.getFieldProps('offenseCode')}
        />
        <Amount
          ticketType={type}
          parkingArea={offenseParkingArea}
          isWarning={isWarning}
          offenseFine={offenseFineAmountInCents}
          offenseExtraFine={offenseExtraFineAmountInCents}
          showFineSurcharge={showFineSurcharge}
          fineSurcharge={fineSurchargeAmountInCents}
          readOnly={formik.status.readOnly}
          // New record identifies as no ticketId in the URL or ticketData is empty.
          // Later is used here.
          newRecord={Object.keys(ticketData).length === 0}
        />
      </div>
      {/* <BYForm.Label>
        {!!offenseCode && !disabled && (
          <FaSearch onClick={() => setShowModal(true)} />
        )}
      </BYForm.Label>
      */}
      <ClarificationWrapper>
        <ClarificationTextarea
          label={I18n.t('activerecord.attributes.ticket.offense_clarification')}
          disabled={disabled}
          readOnly={formik.status.readOnly}
          {...formik.getFieldProps('offenseClarification')}
        />
        {!formik.status.readOnly && (
          <Button onClick={() => setOffenseClarificationVisibility(true)}>
            {I18n.t('tickets.full_ticket.actions.suggestions')}
          </Button>
        )}
      </ClarificationWrapper>

      <CategoryWrapper>
        {(type === 'fiscal' || type === 'juridical' || type === 'taxi') && (
          <Select
            label={I18n.t('activerecord.attributes.ticket.offense_category')}
            name="offenseCategory"
            options={offenseCategoryOptions}
            disabled={disabled}
            readOnly={formik.status.readOnly}
            readOnlyValue={formik.values['offenseCategory']}
            placeholder=""
            error={formik.errors.offenseCategory}
            {...formik.getFieldProps('offenseCategory')}
          />
        )}
        <TowableToggle
          label={I18n.t('activerecord.attributes.ticket.offense_is_towable')}
          name="offenseIsTowable"
          disabled={disabled || formik.status.readOnly}
          isOn={offenseIsTowable}
          onChange={formik.handleChange}
        />
      </CategoryWrapper>

      <WarningToggle
        label={I18n.t('activerecord.attributes.ticket.is_warning')}
        name="isWarning"
        disabled={disabled || formik.status.readOnly}
        isOn={isWarning}
        onChange={formik.handleChange}
      />

      <OffenseClarificationsSearch
        offenseCode={offenseCode}
        show={offenseClarificationModalVisible}
        onHide={() => setOffenseClarificationVisibility(false)}
        onSelect={c => formik.setFieldValue('offenseClarification', c.text)}
      />
    </Card>
  );
};

export default OffenseCard;
