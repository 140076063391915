import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import {
  BsChevronCompactDown,
  BsChevronCompactUp,
  BsChevronCompactLeft,
  BsChevronCompactRight,
  BsX
} from 'react-icons/bs';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { BiReset } from 'react-icons/bi';
import { FaPen } from 'react-icons/fa';
import { TbLine } from 'react-icons/tb';
import { Button } from 'react-bootstrap';
import { Form as BYForm, Loading } from 'brickyard-ui';
import { GET_COUNTRIES } from '@/components/queries/SharedQueries';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ImageCanvas from './ImageCanvas';
import { DEFAULT_IMAGE_FILTER_OPTIONS } from '@/utils/consts';
import {
  DELETE_OBSERVATION_IMAGE,
  PERSIST_OBSERVATION_IMAGE,
  UPDATE_ATTACHMENT_FILE
} from '../../../mutations/cop/use_case_results';
import { Modal } from 'react-bootstrap';

import { Player, ControlBar, VolumeMenuButton, FullscreenToggle, ReplayControl } from 'video-react';
import 'video-react/styles/scss/video-react.scss';
import FullscreenButton from './FullscreenButton';

const IMAGE_TYPE_ORDER = ['overview', 'general', 'video', 'plate', 'panorama', 'edited'];
const IMAGE_SHORT_TYPE_ORDER = ['ov', 'ir', 'pd', 'undefined'];

const getFilename = fullPath => {
  return fullPath.substring(fullPath.lastIndexOf('/') + 1);
};

const getTypeFromName = name => {
  let name_parts = name.split('_');
  return name_parts[name_parts.length - 2] || 'undefined';
};

const imageSortByType = (a, b) => {
  const aIndex = IMAGE_TYPE_ORDER.indexOf(a.type);
  const bIndex = IMAGE_TYPE_ORDER.indexOf(b.type);

  return aIndex - bIndex || a.name.localeCompare(b.name);
};

const imageSortByName = (a, b) => {
  const aIndex = IMAGE_SHORT_TYPE_ORDER.indexOf(getTypeFromName(a.name));
  const bIndex = IMAGE_SHORT_TYPE_ORDER.indexOf(getTypeFromName(b.name));

  return aIndex - bIndex || a.name.localeCompare(b.name);
};

const ImageCarousel = ({
  useCaseResult,
  imageList = null,
  onImageClick,
  onCloseBtnClick,
  onSubmitForm,
  onCancelForm,
  isEditable,
  defaultOpen = 0,
  showHeader = true,
  showHeaderSliders = false,
  showFilterOptions = true,
  showBlur = false,
  setIsEditable,
  canEdit,
  loading,
  formik
}) => {
  const { data: countriesData, loading: countriesLoading } = useQuery(GET_COUNTRIES);

  const images = useCaseResult
    ? [...useCaseResult.files, ...useCaseResult.panoramaFiles, ...useCaseResult.videoFiles]
        .map(file => ({
          id: file.id,
          url: file.url,
          type: file.kind,
          lines: file.virtualLines,
          name: getFilename(file.url),
          images: file.images || []
        }))
        .sort(imageSortByType)
    : imageList.map(image => ({ ...image, name: getFilename(image.url) })).sort(imageSortByName);

  const [persistObservationImage] = useMutation(PERSIST_OBSERVATION_IMAGE);
  const [deleteObservationImage] = useMutation(DELETE_OBSERVATION_IMAGE);
  const [updateAttachmentFile] = useMutation(UPDATE_ATTACHMENT_FILE);

  const [startImageIndex, setStartImageIndex] = useState(Math.max(0, defaultOpen - 3));
  const [selectedImageIndex, setSelectedImageIndex] = useState(defaultOpen);
  const [showLines, setShowLines] = useState(false);
  const [filterOptions, setFilterOptions] = useState(DEFAULT_IMAGE_FILTER_OPTIONS);
  const [imageFilter, setImageFilter] = useState('');

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmReason, setConfirmReason] = useState(null);
  const [imagePersistLoading, setImagePersistLoading] = useState(false);
  const [manualBlurOn, setManualBlurOn] = useState(false);

  const canvasRef = useRef(null);
  const selectionRef = useRef(null);
  const zoomImageRef = useRef(null);
  const playerRef = useRef();

  const handleFullscreen = () => {
    if (playerRef.current) {
      onImageClick(images[selectedImageIndex], selectedImageIndex);
    }
  };

  useEffect(() => {
    if (zoomImageRef.current) {
      zoomImageRef.current.resetTransform();
    }
    setFilterOptions(DEFAULT_IMAGE_FILTER_OPTIONS);
  }, [zoomImageRef, selectedImageIndex]);

  useEffect(() => {
    const filters = filterOptions.map(option => {
      return `${option.property}(${option.value}${option.unit})`;
    });
    setImageFilter(`${filters.join(' ')}`);
  }, [filterOptions]);

  useEffect(() => {
    setShowLines(false);
    setFilterOptions(DEFAULT_IMAGE_FILTER_OPTIONS);
  }, [images[selectedImageIndex]?.id]);

  const onMoveSliderUp = () => {
    if (startImageIndex > 0) {
      setStartImageIndex(startImageIndex - 1);
    }
  };
  const onMoveSliderDown = () => {
    if (startImageIndex < images.length - 4) {
      setStartImageIndex(startImageIndex + 1);
    }
  };

  const onMoveImageLeft = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    } else {
      setSelectedImageIndex(images.length - 1);
      setStartImageIndex(Math.max(images.length - 4, 0));
    }
    if (selectedImageIndex === startImageIndex && startImageIndex > 0) {
      setStartImageIndex(startImageIndex - 1);
    }
  };
  const onMoveImageRight = () => {
    if (selectedImageIndex < images.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    } else {
      setSelectedImageIndex(0);
      setStartImageIndex(0);
    }
    if (selectedImageIndex === startImageIndex + 3 && startImageIndex < images.length - 4) {
      setStartImageIndex(startImageIndex + 1);
    }
  };

  const resetDependentRelatedFields = evt => {
    ['vehicleType', 'vehicleMake', 'vehicleColor', 'offenseCode', 'offenseLocationStreet'].forEach(
      field => {
        formik
          .getFieldProps(field)
          .onChange({ target: { name: field, value: formik.initialValues[field] || '' } });
      }
    );

    formik.getFieldProps(evt.target.name).onChange(evt);
  };

  const shouldShowLinesIcon = () => {
    // eslint-disable-next-line no-undef
    return images[selectedImageIndex].lines && !_.isEmpty(images[selectedImageIndex].lines);
  };

  const handleSliderChange = (currentIndex, target) => {
    setFilterOptions(prevOptions => {
      return prevOptions.map((option, index) => {
        if (index !== currentIndex) return option;
        return { ...option, value: target.value };
      });
    });
  };

  const toggleBlur = () => {
    if (!manualBlurOn) {
      zoomImageRef.current.resetTransform();
    }
    const blurBtn = document.querySelector('#blurbtn');
    blurBtn.textContent = manualBlurOn
      ? I18n.t('settings.image_editor.blur')
      : I18n.t('settings.image_editor.cancel');
    setManualBlurOn(!manualBlurOn);
  };

  const onSaveImage = () => {
    setShowConfirmModal(true);
    setConfirmReason('save');
  };

  const onDeleteImage = () => {
    setShowConfirmModal(true);
    setConfirmReason('delete');
  };

  const closeModal = () => {
    setShowConfirmModal(false);
    setConfirmReason(null);
  };

  const handleImageLoading = () => {
    setImagePersistLoading(true);

    setTimeout(() => {
      setImagePersistLoading(false);
    }, 2500);
  };

  const handleSaveImage = () => {
    closeModal();

    if (useCaseResult) {
      persistObservationImage({
        variables: {
          id: images[selectedImageIndex].id,
          useCaseResultId: useCaseResult.id,
          blur: manualBlurOn,
          blurMeta: manualBlurOn ? JSON.stringify(selectionRef.current) : '{}',
          data: canvasRef.current.toDataURL('image/jpeg').split(',')[1]
        }
      }).then(() => {
        if (images[selectedImageIndex].type !== 'edited') {
          setFilterOptions(DEFAULT_IMAGE_FILTER_OPTIONS);
        }
      });
    } else {
      updateAttachmentFile({
        variables: {
          id: images[selectedImageIndex].id,
          blurMeta: manualBlurOn ? JSON.stringify(selectionRef.current) : '{}',
          data: canvasRef.current.toDataURL('image/jpeg').split(',')[1]
        }
      });
    }
    if (manualBlurOn) {
      toggleBlur();
    }

    handleImageLoading();
  };

  const handleDeleteImage = () => {
    closeModal();

    if (images[selectedImageIndex].type === 'edited') {
      setFilterOptions(DEFAULT_IMAGE_FILTER_OPTIONS);

      deleteObservationImage({
        variables: {
          id: images[selectedImageIndex].id
        }
      }).then(() => {
        setSelectedImageIndex(selectedImageIndex - 1);
      });

      handleImageLoading();
    }
  };

  const handleSliderScroll = evt => {
    if (evt.nativeEvent.wheelDelta > 0) {
      onMoveSliderUp();
    } else {
      onMoveSliderDown();
    }
  };

  if (countriesLoading || imagePersistLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }
  return (
    <div className="styled-image-carousel">
      <div className="image-slider" onWheel={handleSliderScroll}>
        <div className="slider-up-button" onClick={onMoveSliderUp}>
          <BsChevronCompactUp size={40} />
        </div>
        {images.slice(startImageIndex, startImageIndex + 4).map((image, index) => (
          <div
            key={index}
            className={`slider-thumbnail ${startImageIndex + index == selectedImageIndex &&
              'selected'}`}
            onClick={() => {
              setSelectedImageIndex(startImageIndex + index);
            }}
          >
            {image.type === 'video' ? (
              <video width="100%" height="100%" src={`${image.url}#t=5`} />
            ) : (
              <img src={image.url} />
            )}
          </div>
        ))}
        <div className="slider-down-button" onClick={onMoveSliderDown}>
          <BsChevronCompactDown size={40} />
        </div>
      </div>
      <div className="image-right-container">
        {showHeader && (
          <div className={showHeaderSliders ? 'container-header small' : 'container-header'}>
            <div className="header-plate-image">
              <img src={images.find(img => img.type === 'plate')?.url} />
            </div>
            <div className="header-plate-field">
              <div className="header-plate-field-country">
                {!isEditable ? (
                  <p>{useCaseResult?.countryCode}</p>
                ) : (
                  <BYForm.Group>
                    <BYForm.Control
                      disabled={!isEditable}
                      readOnly={!isEditable}
                      size="sm"
                      as="select"
                      {...formik.getFieldProps('countryCode')}
                      onChange={resetDependentRelatedFields}
                      className="license-country-code mt-1 pl-0 pr-0"
                    >
                      <option />
                      {!!(
                        useCaseResult?.countryCode &&
                        !countriesData.countries.find(c => c.code === useCaseResult?.countryCode)
                      ) && (
                        <option value={useCaseResult?.countryCode}>
                          {useCaseResult?.countryCode}
                        </option>
                      )}
                      {countriesData.countries.map(c => (
                        <option value={c.code} key={c.id}>
                          {c.code}
                        </option>
                      ))}
                    </BYForm.Control>
                  </BYForm.Group>
                )}
              </div>
              <div className={`header-plate-field-number ${isEditable ? 'enabled' : ''}`}>
                {!isEditable ? (
                  <p>{useCaseResult?.vehicleLicensePlateNumber}</p>
                ) : (
                  <BYForm.Group>
                    <BYForm.Control
                      disabled={!isEditable}
                      readOnly={!isEditable}
                      size="sm"
                      {...formik.getFieldProps('vehicleLicensePlateNumber')}
                      onChange={resetDependentRelatedFields}
                      className="license-plate-input"
                    />
                  </BYForm.Group>
                )}
              </div>
              <p className="dupl-code-floating-label">dupl.</p>
              <div className={`header-duplicate-code ${isEditable ? 'enabled' : ''}`}>
                {!isEditable ? (
                  <p>{useCaseResult?.duplicateCode || '-'}</p>
                ) : (
                  <BYForm.Group>
                    <BYForm.Control
                      min={0}
                      max={99}
                      type="number"
                      placeholder="-"
                      disabled={!isEditable}
                      readOnly={!isEditable}
                      size="sm"
                      {...formik.getFieldProps('duplicateCode')}
                      isInvalid={!!formik.errors.duplicateCode}
                      className="duplicate-code-input"
                    />
                  </BYForm.Group>
                )}
              </div>
            </div>
            <div className="header-buttons">
              {canEdit &&
                (!isEditable ? (
                  <div className="header-edit-button" onClick={() => setIsEditable(true)}>
                    <FaPen size={24} />
                  </div>
                ) : loading ? (
                  <Loading size="md" variant="by-dark" />
                ) : (
                  <>
                    <div className="header-save-button" onClick={onSubmitForm}>
                      <Button variant="primary" size="md" disabled={loading}>
                        {I18n.t('settings.form_save_button')}
                      </Button>
                    </div>
                    <div className="header-cancel-button" onClick={onCancelForm}>
                      <Button variant="outline-primary" size="md" disabled={loading}>
                        ✕
                      </Button>
                    </div>
                  </>
                ))}
            </div>
          </div>
        )}
        {showHeaderSliders && (
          <div className="editor-container">
            {showFilterOptions && !manualBlurOn && (
              <>
                {filterOptions.map((option, index) => (
                  <div className="slider-container" key={`${option.property}`}>
                    <span className={'pr-2'}>
                      {I18n.t(`settings.image_editor.${option.property}`)}
                    </span>
                    <input
                      type="range"
                      className="slider"
                      name={option.name}
                      min={option.range.min}
                      max={option.range.max}
                      value={option.value}
                      onChange={evt => handleSliderChange(index, evt.target)}
                    />
                  </div>
                ))}
              </>
            )}
            {showBlur && images[selectedImageIndex] && (
              <>
                <div className="header-btn">
                  <Button variant="danger" id="blurbtn" size="md" onClick={toggleBlur}>
                    {I18n.t('settings.image_editor.blur')}
                  </Button>
                </div>
              </>
            )}
            {(showFilterOptions || showBlur) && (
              <>
                <div
                  className="header-btn"
                  style={{
                    display: showFilterOptions || (showBlur && manualBlurOn) ? 'block' : 'none'
                  }}
                >
                  <Button variant="primary" size="md" onClick={onSaveImage}>
                    {I18n.t('settings.image_editor.save')}
                  </Button>
                </div>
                <div className="header-btn">
                  {images[selectedImageIndex]?.type === 'edited' && (
                    <Button variant="danger" size="md" onClick={onDeleteImage}>
                      {I18n.t('settings.image_editor.delete')}
                    </Button>
                  )}
                </div>
              </>
            )}
            <div className="close-btn" onClick={onCloseBtnClick}>
              <BsX size={60} />
            </div>
          </div>
        )}
        <div className="container-body">
          {images.length > 0 && (
            <>
              {images.length > 1 && !manualBlurOn && (
                <div className="container-button" onClick={onMoveImageLeft}>
                  <BsChevronCompactLeft size={60} />
                </div>
              )}
              {!showHeaderSliders ? (
                images[selectedImageIndex].type === 'video' ? (
                  <Player
                    ref={playerRef}
                    fluid={false}
                    width="70%"
                    height="100%"
                    preload="auto"
                    autoPlay={true}
                    className={!showHeader ? 'full-screen' : ''}
                  >
                    <source src={images[selectedImageIndex].url} />
                    <ControlBar autoHide={false}>
                      <VolumeMenuButton disabled />
                      <FullscreenToggle disabled />
                      <ReplayControl seconds={10} order={1.1} />
                      {showHeader && <FullscreenButton onClick={handleFullscreen} order={6.1} />}
                    </ControlBar>
                  </Player>
                ) : (
                  <>
                    <div className="container-toolbar small">
                      {shouldShowLinesIcon() && (
                        <div className="toolbar-btn small">
                          <TbLine
                            size={40}
                            color={showLines ? 'orange' : undefined}
                            onClick={() => setShowLines(!showLines)}
                          />
                        </div>
                      )}
                    </div>
                    <ImageCanvas
                      image={images[selectedImageIndex]}
                      lines={images[selectedImageIndex].lines}
                      showLines={showLines}
                      selectionRef={selectionRef}
                      onClick={() => onImageClick(images[selectedImageIndex], selectedImageIndex)}
                    />
                  </>
                )
              ) : (
                <TransformWrapper
                  disablePadding={true}
                  style={{ width: '100%', height: '100%', display: 'flex' }}
                  ref={zoomImageRef}
                  key={selectedImageIndex}
                >
                  {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                      {!manualBlurOn && (
                        <div className="container-toolbar">
                          <div className="toolbar-btn">
                            <AiOutlineZoomIn size={40} onClick={() => zoomIn()} />
                          </div>
                          <div className="toolbar-btn">
                            <AiOutlineZoomOut size={40} onClick={() => zoomOut()} />
                          </div>
                          <div className="toolbar-btn">
                            <BiReset size={40} onClick={() => resetTransform()} />
                          </div>
                          {shouldShowLinesIcon() && (
                            <div className="toolbar-btn">
                              <TbLine
                                size={40}
                                color={showLines ? 'orange' : undefined}
                                onClick={() => setShowLines(!showLines)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      <TransformComponent>
                        {images[selectedImageIndex].type === 'video' ? (
                          <Player
                            ref={playerRef}
                            fluid={false}
                            width="70%"
                            height="100%"
                            preload="auto"
                            autoPlay={true}
                          >
                            <source src={images[selectedImageIndex].url} />
                            <ControlBar autoHide={false}>
                              <ReplayControl seconds={10} order={1.1} />
                              <VolumeMenuButton disabled />
                              <FullscreenToggle disabled />
                            </ControlBar>
                          </Player>
                        ) : (
                          <ImageCanvas
                            image={images[selectedImageIndex]}
                            lines={images[selectedImageIndex].lines}
                            showLines={showLines}
                            imageFilter={imageFilter}
                            selectionRef={selectionRef}
                            outsideRef={canvasRef}
                            manualBlurOn={manualBlurOn}
                          />
                        )}
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              )}
              {images.length > 1 && !manualBlurOn && (
                <div className="container-button right-button" onClick={onMoveImageRight}>
                  <BsChevronCompactRight size={60} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showConfirmModal && (
        <Modal show={showConfirmModal} onHide={closeModal}>
          <Modal.Body>
            {confirmReason === 'save'
              ? images[selectedImageIndex].type === 'edited' || manualBlurOn
                ? I18n.t('settings.image_editor.save_overwrite_confirm')
                : I18n.t('settings.image_editor.save_copy_confirm')
              : I18n.t('settings.image_editor.delete_confirm')}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={confirmReason === 'save' ? handleSaveImage : handleDeleteImage}
            >
              {I18n.t('settings.image_editor.confirm')}
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              {I18n.t('settings.image_editor.cancel')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ImageCarousel;
