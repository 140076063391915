import React, { useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../components/FormFieldSmall';
import { useDispatch, useSelector } from 'react-redux';
import ParkingRightProviderField from './fields/ParkingRightProviderField';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import FormField from '../../../../components/FormField';
import {
  DATETIME_USED_IN_QUERY_OPTIONS,
  MINUTES_AFTER_OBSERVATION
} from '../../../../../../../../utils/consts';
import { externalRightCheckerSchema } from './validationSchema';

function ExternalRightChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedProcessor, parkingRightProviders } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();
  const minutesDelayedFromObservationTime =
    selectedProcessor?.config?.minutes_delayed_from_observation_time || null;

  const selectedParkingRightProviders = selectedProcessor?.config?.right_providers
    ? selectedProcessor?.config?.right_providers?.map(prp => ({
        id: prp,
        name: prp
      }))
    : [];
  const availableParkingRightProviders = parkingRightProviders?.filter(
    provider =>
      !selectedParkingRightProviders?.find(selectedProvider => selectedProvider.id === provider.id)
  );

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      datetime_used_in_query: selectedProcessor?.config?.datetime_used_in_query,
      presume_right: selectedProcessor?.config?.presume_right ?? false,
      presume_maybe: selectedProcessor?.config?.presume_maybe ?? true,
      parking_code: selectedProcessor?.config?.parking_code,
      minutes_delayed_from_observation_time: minutesDelayedFromObservationTime
        ? parseInt(minutesDelayedFromObservationTime)
        : undefined,
      availableParkingRightProviders,
      selectedParkingRightProviders
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'presume_right',
          'presume_maybe',
          'parking_code',
          'selectedParkingRightProviders',
          'datetime_used_in_query',
          'minutes_delayed_from_observation_time'
        ])
      );
    },
    validationSchema: externalRightCheckerSchema
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    const configToSave = {
      parking_code: values.parking_code,
      presume_right: values.presume_right,
      presume_maybe: values.presume_maybe,
      right_providers: values.selectedParkingRightProviders?.map(provider => provider.id),
      datetime_used_in_query: values.datetime_used_in_query
    };

    if (values.datetime_used_in_query === MINUTES_AFTER_OBSERVATION) {
      configToSave.minutes_delayed_from_observation_time = parseInt(
        values.minutes_delayed_from_observation_time
      );
    }
    const params = {
      id: values.id,
      name: values.name,
      config: configToSave
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <div className={selectedProcessor?.className ?? ''}>
      <FormFieldSmall
        label={I18n.t('settings.pipelines.processors.form.external_right_checker.presume_right')}
        field={
          <LabelToggle
            id="presume_right"
            name="presume_right"
            isOn={formik.values.presume_right ?? false}
            onChange={formik.handleChange}
            disabled={!editEnabled}
          />
        }
        error={formik.errors.presume_right}
      />

      <FormFieldSmall
        label={I18n.t('settings.pipelines.processors.form.external_right_checker.presume_maybe')}
        field={
          <LabelToggle
            id="presume_maybe"
            name="presume_maybe"
            isOn={formik.values.presume_maybe ?? false}
            onChange={formik.handleChange}
            disabled={!editEnabled}
          />
        }
        error={formik.errors.presume_maybe}
      />

      <FormField
        label={I18n.t(
          'settings.pipelines.processors.form.external_right_checker.select_datetime_used_in_query'
        )}
        field={
          <select
            id="datetime_used_in_query"
            name="datetime_used_in_query"
            disabled={!editEnabled}
            onChange={formik.handleChange}
            value={formik.values.datetime_used_in_query}
          >
            <option value=""></option>
            {DATETIME_USED_IN_QUERY_OPTIONS.map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        }
        error={formik.errors.datetime_used_in_query}
      />

      {formik.values.datetime_used_in_query === MINUTES_AFTER_OBSERVATION && (
        <FormField
          label={I18n.t(
            'settings.pipelines.processors.form.external_right_checker.minutes_delayed_from_observation_time'
          )}
          field={
            <input
              id="minutes_delayed_from_observation_time"
              name="minutes_delayed_from_observation_time"
              type="number"
              value={formik.values.minutes_delayed_from_observation_time}
              disabled={!editEnabled}
              onChange={formik.handleChange}
            />
          }
          error={formik.errors.minutes_delayed_from_observation_time}
        />
      )}

      <FormField
        label={I18n.t('settings.pipelines.processors.form.external_right_checker.parking_code')}
        field={
          <input
            id="parking_code"
            name="parking_code"
            type="string"
            value={formik.values.parking_code}
            disabled={!editEnabled}
            onChange={formik.handleChange}
          />
        }
        error={formik.errors.parking_code}
      />

      <ParkingRightProviderField
        formik={formik}
        formLabel={I18n.t(
          'settings.pipelines.processors.form.external_right_checker.parking_right_providers'
        )}
        editEnabled={editEnabled}
        isSwitchable={false}
      />
    </div>
  );
}

export default ExternalRightChecker;
