import React, { useState, useEffect } from 'react';

function ImageCanvas({
  image,
  lines,
  showLines,
  imageFilter,
  outsideRef,
  manualBlurOn,
  selectionRef,
  ...props
}) {
  const canvasRef = React.useRef(null);
  const imageCanvasRef = outsideRef || React.useRef(null);
  const lineCanvasRef = React.useRef(null);
  const footerCanvasRef = React.useRef(null);
  const footer = false;

  const [selection, setSelection] = useState({ x: 150, y: 150, width: 200, height: 100 });
  const selRef = selectionRef || React.useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [resizeCorner, setResizeCorner] = useState(null);
  let ratio = 1;
  const [imageElement, setImageElement] = useState(new Image());

  const drawLine = (ctx, line) => {
    ctx.beginPath();
    ctx.moveTo(line.startX, line.startY);
    ctx.lineTo(line.endX, line.endY);
    ctx.lineWidth = 7;

    ctx.stroke();
  };

  React.useEffect(() => {
    imageElement.src = image.url;
    setImageElement(imageElement);
    imageElement.onload = () => {
      const canvas = canvasRef.current;
      const footer = image?.images?.find(img => img.kind === 'footer');
      canvas.width = imageElement.width;
      canvas.height = imageElement.height;
      if (footer) {
        const footerCanvas = footerCanvasRef.current;
        const footerCtx = footerCanvas.getContext('2d');
        const footerImg = new Image();
        const ctx = canvas.getContext('2d');
        footerImg.src = footer.url;
        footerImg.onload = () => {
          footerCanvas.width = footerImg.width;
          footerCanvas.height = footerImg.height;

          canvas.height = imageElement.height + footerImg.height;

          footerCtx.drawImage(footerImg, 0, 0);

          renderImage();
          if (footerCanvasRef.current) {
            ctx.drawImage(footerCanvasRef.current, 0, imageElement.height);
          }
        };
      } else {
        renderImage();
      }
    };
  }, [image, lines, showLines, imageFilter]);

  React.useEffect(() => {
    const draw = () => {
      renderImage();
    };
    requestAnimationFrame(draw);
  }, [selection]);

  const renderImage = () => {
    if (imageElement.width === 0 || imageElement.height === 0) {
      return;
    }
    const canvas = canvasRef.current;
    if (!canvas) return;

    const imgCanvas = imageCanvasRef.current;
    const lineCanvas = lineCanvasRef.current;

    const ctx = canvas.getContext('2d');
    const lineCtx = lineCanvas.getContext('2d');

    imgCanvas.width = imageElement.width;
    imgCanvas.height = imageElement.height;

    lineCanvas.width = imageElement.width;
    lineCanvas.height = imageElement.height;
    ratio = imageElement.width / canvas.offsetWidth;
    selRef.current = {
      x: Math.round(selection.x * ratio),
      y: Math.round(selection.y * ratio),
      width: Math.round(selection.width * ratio),
      height: Math.round(selection.height * ratio)
    };

    drawCanvas();

    // lines are drawn in the line canvas
    if (lines && showLines) {
      if (lines.entry) {
        lineCtx.strokeStyle = 'yellow';
        drawLine(lineCtx, {
          startX: lines.entry.start_x,
          startY: lines.entry.start_y,
          endX: lines.entry.end_x,
          endY: lines.entry.end_y
        });
      }

      if (lines.exit) {
        lineCtx.strokeStyle = 'blue';
        drawLine(lineCtx, {
          startX: lines.exit.start_x,
          startY: lines.exit.start_y,
          endX: lines.exit.end_x,
          endY: lines.exit.end_y
        });
      }

      if (lines.violation) {
        lineCtx.strokeStyle = 'red';
        drawLine(lineCtx, {
          startX: lines.violation.start_x,
          startY: lines.violation.start_y,
          endX: lines.violation.end_x,
          endY: lines.violation.end_y
        });
      }
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (imageCanvasRef.current) {
      ctx.drawImage(imageCanvasRef.current, 0, 0);
    }
    if (lineCanvasRef.current) {
      ctx.drawImage(lineCanvasRef.current, 0, 0);
    }

    // Draw selection rectangle
    drawSelectionRectangle(ctx);
  };

  const drawCanvas = () => {
    const imgCanvas = imageCanvasRef.current;
    const ctx = imgCanvas.getContext('2d');
    // image is drawn in the image canvas
    ctx.filter = manualBlurOn ? '' : imageFilter || '';

    ctx.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);

    // Apply blur effect to selection
    if (manualBlurOn) {
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = selection.width * ratio;
      tempCanvas.height = selection.height * ratio;
      tempCtx.filter = 'blur(' + 10 * ratio + 'px)';
      tempCtx.drawImage(
        imageElement,
        selection.x * ratio,
        selection.y * ratio,
        selection.width * ratio,
        selection.height * ratio,
        0,
        0,
        selection.width * ratio,
        selection.height * ratio
      );
      ctx.drawImage(tempCanvas, selection.x * ratio, selection.y * ratio);
    }
  };

  const drawSelectionRectangle = ctx => {
    if (!manualBlurOn) return;
    ctx.setLineDash([5 * ratio, 5 * ratio]);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2 * ratio;
    ctx.strokeRect(
      selection.x * ratio,
      selection.y * ratio,
      selection.width * ratio,
      selection.height * ratio
    );

    // Draw resize handles
    ctx.setLineDash([]);
    ctx.fillStyle = 'blue';
    const handleSize = 8 * ratio;
    ctx.fillRect(
      selection.x * ratio + selection.width * ratio - handleSize / 2,
      selection.y * ratio + selection.height * ratio - handleSize / 2,
      handleSize,
      handleSize
    );
  };

  const handleMouseDown = e => {
    if (!manualBlurOn) return;
    const { offsetX, offsetY } = e.nativeEvent;
    const handleSize = 8;
    const isInResizeZone =
      offsetX > selection.x + selection.width - handleSize &&
      offsetX < selection.x + selection.width + handleSize &&
      offsetY > selection.y + selection.height - handleSize &&
      offsetY < selection.y + selection.height + handleSize;

    if (isInResizeZone) {
      setIsResizing(true);
    } else if (
      offsetX > selection.x &&
      offsetX < selection.x + selection.width &&
      offsetY > selection.y &&
      offsetY < selection.y + selection.height
    ) {
      setIsDragging(true);
      setOffset({ x: offsetX - selection.x, y: offsetY - selection.y });
    }
  };

  const selectionInImage = selection => {
    if ((selection.x + selection.width) * ratio > imageElement.width) {
      return false;
    }
    if ((selection.y + selection.height) * ratio > imageElement.height) {
      return false;
    }
    if (selection.x < 0 || selection.y < 0) {
      return false;
    }
    return true;
  };

  const handleMouseMove = e => {
    if (!manualBlurOn) return;
    const { offsetX, offsetY } = e.nativeEvent;
    if (isDragging) {
      const newSelection = {
        x: offsetX - offset.x,
        y: offsetY - offset.y,
        width: selection.width,
        height: selection.height
      };
      if (selectionInImage(newSelection)) {
        setImageSelection(newSelection);
      }
    } else if (isResizing) {
      const newSelection = {
        x: selection.x,
        y: selection.y,
        width: Math.max(20, offsetX - selection.x),
        height: Math.max(20, offsetY - selection.y)
      };
      if (selectionInImage(newSelection)) {
        setImageSelection(newSelection);
      }
    }
  };

  const setImageSelection = newSelection => {
    setSelection(newSelection);
    selRef.current = {
      x: Math.round(newSelection.x * ratio),
      y: Math.round(newSelection.y * ratio),
      width: Math.round(newSelection.width * ratio),
      height: Math.round(newSelection.height * ratio)
    };
  };

  const handleMouseUp = () => {
    if (!manualBlurOn) return;
    setIsDragging(false);
    setIsResizing(false);
  };

  selRef.current = {
    x: Math.round(selection.x * ratio),
    y: Math.round(selection.y * ratio),
    width: Math.round(selection.width * ratio),
    height: Math.round(selection.height * ratio)
  };

  return (
    <>
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        {...props}
        style={{
          margin: 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          cursor: isDragging || isResizing ? 'grabbing' : 'default'
        }}
      />
      <div style={{ display: 'none' }}>
        <canvas ref={imageCanvasRef} />
        <canvas ref={lineCanvasRef} />
        <canvas ref={footerCanvasRef} />
      </div>
    </>
  );
}

export default ImageCanvas;
